require('./bootstrap');
require('svg.js');
const Cookies = require('js-cookie');

const cookiesBar = $('#cookiesBar');

if (Cookies.get('cookiesBar_remember')) {
    cookiesBar.remove();
}

cookiesBar.find('.btn').on('click', function () {
    Cookies.set('cookiesBar_remember', 1, {expires: 365});
    cookiesBar.remove();
});

const drawBackgroundLines = function () {
    $('#backgroundLines svg').remove();
    const draw = SVG('backgroundLines');
    draw.size($(window).width(), 'auto');
    draw.viewbox(0, 0, 2000, 1200, 'xMinYMin meet');
    draw.line(0, 1200, 700, 0).stroke({
        width: 1,
        color: '#d9ebfe'
    });
    draw.line(0, 115, 2000, 630).stroke({
        width: 1,
        color: '#d9ebfe'
    });
    draw.line(0, 950, 2000, 590).stroke({
        width: 1,
        color: '#d9ebfe'
    });
};

$(function () {
    drawBackgroundLines();
});

$(window).on('resize', function () {
    drawBackgroundLines();
});

const navigation = $('.navigation');
const navigation_hamburger = navigation.find('.hamburger');
const navigation_menu = navigation.find('.menu');

$(window).on('scroll', function () {
    let windowScrollTop = $(this).scrollTop();

    if (windowScrollTop > 0) {
        navigation.addClass('navigation--fixed');
    } else {
        navigation.removeClass('navigation--fixed');
    }
});

navigation_hamburger.on('click', function () {
    navigation.toggleClass('navigation--open');
    navigation_menu.toggleClass('d-none');
});

navigation.find('[data-scroll-to]').on('click touchend', function (event) {
    navigation.removeClass('navigation--open');
    navigation_menu.addClass('d-none');
});

navigation.find('a[href]').on('click touchend', function (event) {
    let link = $(this).attr('href');
    window.location.href = link;
});

$('.custom-file-input').on('change', function () {
    var fileName = $(this).val();
    $(this).next('.custom-file-label').text(fileName);
});

$('.scroll-to-top').on('click', function () {
    $('html, body').animate({scrollTop: 0}, 500);
});

$('input, select, textarea').each(function () {
    let input = $(this);
    let label;

    if (input.prop('required')) {
        if (input.attr('type') === 'file') {
            label = input.parent().parent().children('label');
        } else {
            label = input.parent().children('label');
        }

        label.append('<span class="text-danger text-weight-bold ml-1">*</span>');
    }
});

$('[data-scroll-to]').on('click', function () {
    let selector = $(this).data('scroll-to');
    scrollTo(selector);
});

function scrollTo (selector) {
    let bodyMarginTop = parseFloat($('body').css('margin-top'));
    let sectionPaddingTop = parseFloat($(selector).css('padding-top'));
    let sectionContainerPaddingTop = parseFloat($(selector).find('.container').css('padding-top'));

    $('html, body').animate({
        scrollTop: ($(selector).offset().top) - bodyMarginTop + sectionPaddingTop + sectionContainerPaddingTop - 15
    }, 550);
}
